function FeedbackScale({id,selectedScale,handleSelectedScale}) {
    return (
        <div className="d-flex justify-content-between max-w-scale">
            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option1"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option1"} data-value="1" onClick={handleSelectedScale}>1</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option2"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option2"} data-value="2" onClick={handleSelectedScale}>2</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option3"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option3"} data-value="3" onClick={handleSelectedScale}>3</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option4"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option4"} data-value="4" onClick={handleSelectedScale}>4</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option5"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option5"} data-value="5" onClick={handleSelectedScale}>5</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option6"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option6"} data-value="6" onClick={handleSelectedScale}>6</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option7"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option7"} data-value="7" onClick={handleSelectedScale}>7</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option8"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option8"} data-value="8" onClick={handleSelectedScale}>8</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option9"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option9"} data-value="9" onClick={handleSelectedScale}>9</label>

            <input type="radio" className="btn-check" name={"options-"+id} id={id+"-option10"} />
            <label className="btn btn-outline-danger rounded-circle fs-4 p-0" htmlFor={id+"-option10"} data-value="10" onClick={handleSelectedScale}>10</label>
        </div>
    );
}

export default FeedbackScale;