import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

function Squad({ notify, warning }) {
    const initialState = {
        gender: "",
        age: "",
        nationality: "",
        interests: "",
        area: "",
        about: ""
    }
    const [showOtherNationality, setShowOtherNationality] = useState(false)
    const [showOtherAbout, setShowOtherAbout] = useState(true)
    const [OtherNationality, setOtherNationality] = useState('')
    const [Interests, setInterests] = useState([])
    const [About, setAbout] = useState([])
    const [OtherAbout, setOtherAbout] = useState('')
    const [formData, setFormData] = useState(initialState)
    const updateFormData = (paramName, paramValue) => {
        setFormData((prevOrderData) => ({
            ...prevOrderData,
            [paramName]: paramValue,
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    function handleNationality(e) {
        const nationality = e.target.value
        setOtherNationality('')
        if (nationality === "others") {
            setShowOtherNationality(true)
        } else {
            setShowOtherNationality(false)
        }
        updateFormData("nationality", e.target.value)
    }

    function handleOtherNationality(e) {
        const nationality = e.target.value
        setOtherNationality(nationality)
        updateFormData("nationality", nationality)
    }

    function handleInterests(event) {
        const { value, checked } = event.target;

        if (checked) {
            setInterests((prevSelectedItems) => [...prevSelectedItems, value]);
        } else {
            setInterests((prevSelectedItems) =>
                prevSelectedItems.filter((item) => item !== value)
            );
        }

    }

    function handleAbout(event) {
        const { value, checked } = event.target;

        if (checked) {
            if (value === "Others") setShowOtherAbout(false)
            setAbout((prevSelectedItems) => [...prevSelectedItems, value]);
        } else {
            if (value === "Others") {
                setShowOtherAbout(true)
                setOtherAbout('')
                setAbout((prevSelectedItems) =>
                    prevSelectedItems.filter((item) => !item.includes("Others:"))
                );
            }
            setAbout((prevSelectedItems) =>
                prevSelectedItems.filter((item) => item !== value)
            );
        }

    }

    function handleOtherAbout(e) {
        const otherAbout = e.target.value
        setOtherAbout(otherAbout)
    }

    function handleOtherAbout2() {
        setAbout((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item !== "Others")
        );
        setAbout((prevSelectedItems) => [...prevSelectedItems, "Others:" + OtherAbout]);
    }

    function handleAreaChange(e) {
        const area = e.target.value
        updateFormData("area", area)
    }

    useEffect(() => {
        updateFormData("interests", Interests.toString())
    }, [Interests]);

    useEffect(() => {
        updateFormData("about", About.toString())
    }, [About]);


    async function handleAddSquad() {
        let url = 'https://qrportalbackend.azurewebsites.net/squad/sushido'
        const params = formData
        const hasEmptyValue = Object.values(params).some(value => value === "")
        if (hasEmptyValue) warning("Please fill all form data.")
        else {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                const result = responseData.error
                if (result) {
                    warning("Error adding squad member!")
                }
                else {
                    setFormData(initialState)
                    notify("Squad member is added.")
                }
            } catch (error) {
                console.error('Error while sending POST request:', error);
            }
        }
    }

    return (
        <>
            <Container className="mt-5">
                <h1 className="text-center text-uppercase text-light text-shadow secondary-font mb-5">SUSHI SQUAD ASSEMBLE</h1>
                <Form>
                    <Row className="text-left mb-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">Gender:</h2>
                            <div>
                                <Form.Check
                                    type="radio"
                                    name="gender"
                                    label="Male"
                                    className="text-light secondary-font fs-5"
                                    value="male"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="gender"
                                    label="Female"
                                    className="text-light secondary-font fs-5"
                                    value="female"
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-left my-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">Age Group:</h2>
                            <div>
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="Below 21"
                                    value="Below 21"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="21-25"
                                    value="21-25"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="26-30"
                                    value="26-30"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="30-34"
                                    value="30-34"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="35-39"
                                    value="35-39"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    name="age"
                                    label="Above 39"
                                    value="Above 39"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-left my-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">NATIONALITY:</h2>
                            <div>
                                <Form.Check
                                    type="radio"
                                    name="nationality"
                                    label="Emirati"
                                    value="Emirati"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleNationality}
                                />
                                <Form.Check
                                    type="radio"
                                    name="nationality"
                                    label="Arab expatriate"
                                    value="Arab expatriate"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleNationality}
                                />
                                <Form.Check
                                    type="radio"
                                    name="nationality"
                                    label="Western expatriate"
                                    value="Western expatriate"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleNationality}
                                />
                                <Form.Check
                                    type="radio"
                                    name="nationality"
                                    label="Asian expatriate"
                                    value="Asian expatriate"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleNationality}
                                />
                                <Form.Check
                                    type="radio"
                                    name="nationality"
                                    label="Others (Please specify)"
                                    value="others"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleNationality}
                                />
                                <Form.Group className={showOtherNationality ? "mt-3" : "mt-3 d-none"} controlId="otherNationality" >
                                    <Form.Control type="text" value={OtherNationality} onChange={handleOtherNationality} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-left my-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">INTERESTS: <span className="text-light secondary-font fs-5">(Multiple Selections Allowed)</span></h2>
                            <div>
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Food and Dining"
                                    value="Food and Dining"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Fashion and Beauty"
                                    value="Fashion and Beauty"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Entertainment (Movies, Music, Events)"
                                    value="Entertainment (Movies, Music, Events)"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Travel and Adventure"
                                    value="Travel and Adventure"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Arts and Culture"
                                    value="Arts and Culture"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Sports, Fitness and Wellness"
                                    value="Sports, Fitness and Wellness"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Technology"
                                    value="Technology"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="interests"
                                    label="Reading and Education"
                                    value="Reading and Education"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleInterests}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-left my-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">AREA OF RESIDENCE IN UAE/KSA:</h2>
                            <div>
                                <Form.Group className="mb-3" controlId="area">
                                    <Form.Control type="text" onChange={handleAreaChange} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-left my-5">
                        <Col>
                            <h2 className="text-uppercase text-light primary-font mb-3">HOW DID YOU HEAR ABOUT US ?: <span className="text-light secondary-font fs-5">(Multiple Selections Allowed)</span></h2>
                            <div>
                                <Form.Check
                                    type="checkbox"
                                    name="about"
                                    label="Delivery Apps – (Talabat, Deliveroo, Careem, Noon etc.)"
                                    value="Delivery Apps – (Talabat, Deliveroo, Careem, Noon etc.)"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleAbout}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="about"
                                    label="Social Media (Instagram, TikTok)"
                                    value="Social Media (Instagram, TikTok)"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleAbout}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="about"
                                    label="Word of Mouth"
                                    value="Word of Mouth"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleAbout}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="about"
                                    label="Advertisements (Digital, Outdoor)"
                                    value="Advertisements (Digital, Outdoor)"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleAbout}
                                />
                                <Form.Check
                                    type="checkbox"
                                    name="about"
                                    label="Other (please specify)"
                                    value="Others"
                                    className="text-light secondary-font fs-5"
                                    onChange={handleAbout}
                                />
                                <Form.Group className="mt-3" controlId="otherAbout">
                                    <Form.Control type="text" value={OtherAbout} onChange={handleOtherAbout} onBlur={handleOtherAbout2} disabled={showOtherAbout} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col onClick={handleAddSquad}>
                            <Button className="btn-submit" >Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
}

export default Squad;