import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'

function Section4() {
    return (
        <>
            <Row className="text-center px-socials w-100 mx-auto pt-3"><h1 className="text-uppercase text-light text-social secondary-font fs-xs-social"><span>FOLLOW US ON INSTAGRAM</span></h1></Row>
            <Image src="./assets/instagram-feeds.jpg" className='mt-5 w-100' />
        </>
    );
}

export default Section4;