import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FEEDBACKSCALE from './feedback-scale'

function Feedback({ notify, warning }) {
    const initialState = {
        taste: 0,
        quality: 0,
        delivery: 0,
        money: 0,
        recommend: 0,
        feedback: ""
    }
    const [formData, setFormData] = useState(initialState)
    const [selectedTasteScale, setSelectedTasteScale] = useState('')
    const [selectedQualityScale, setSelectedQualityScale] = useState('')
    const [selectedDeliveryScale, setSelectedDeliveryScale] = useState('')
    const [selectedMoneyScale, setSelectedMoneyScale] = useState('')
    const [selectedRecommendScale, setSelectedRecommendScale] = useState('')

    const updateFormData = (paramName, paramValue) => {
        setFormData((prevOrderData) => ({
            ...prevOrderData,
            [paramName]: paramValue,
        }));
    };
    function handleFeedback(e) {
        updateFormData("feedback", e.target.value)
    }
    useEffect(() => {
        if (selectedTasteScale !== '') updateFormData("taste", selectedTasteScale.target.getAttribute('data-value'))
    }, [selectedTasteScale]);
    useEffect(() => {
        if (selectedQualityScale !== '') updateFormData("quality", selectedQualityScale.target.getAttribute('data-value'))
    }, [selectedQualityScale]);
    useEffect(() => {
        if (selectedDeliveryScale !== '') updateFormData("delivery", selectedDeliveryScale.target.getAttribute('data-value'))
    }, [selectedDeliveryScale]);
    useEffect(() => {
        if (selectedMoneyScale !== '') updateFormData("money", selectedMoneyScale.target.getAttribute('data-value'))
    }, [selectedMoneyScale]);
    useEffect(() => {
        if (selectedRecommendScale !== '') updateFormData("recommend", selectedRecommendScale.target.getAttribute('data-value'))
    }, [selectedRecommendScale]);


    async function handleAddfeedback() {
        let url = 'https://qrportalbackend.azurewebsites.net/feedback/sushido'
        const params = formData
        console.log(params)
        const hasEmptyValue = Object.values(params).some(value => value === 0)
        console.log(hasEmptyValue)
        if (hasEmptyValue) warning("Please fill all form data.")
        else {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                const result = responseData.error
                if (result) {
                    warning("Error Adding Feedback.")
                }
                else {
                    setFormData(initialState)
                    notify("Feedback is Added.")
                }
            } catch (error) {
                console.error('Error while sending POST request:', error);
            }
        }
    }
    return (
        <>
            <Container className="mt-5">
                <h1 className="text-center text-uppercase text-light text-shadow secondary-font mb-5">Feedback Form</h1>
                <Row className="text-left">
                    <Col>
                        <h2 className="text-uppercase text-light primary-font fs-xs-feedbak-q">HOW WOULD YOU RATE US ON THESE PARAMETERS?</h2>
                        <span className="text-light secondary-font fs-feedbak-s">( 1 Being Very Bad and 10 Being Very Good )</span>
                        <div>
                            <h3 className="text-light secondary-font fs-feedbak-s mt-4">Taste</h3>
                            <span className="text-light secondary-font fs-feedbak-s">(Scale of 1-10)</span>
                            <FEEDBACKSCALE id="taste" handleSelectedScale={setSelectedTasteScale} />
                        </div>
                        <div>
                            <h3 className="text-light secondary-font fs-feedbak-s mt-4">Quality of Ingredients</h3>
                            <span className="text-light secondary-font fs-feedbak-s">(Scale of 1-10)</span>
                            <FEEDBACKSCALE id="quality" handleSelectedScale={setSelectedQualityScale} />
                        </div>
                        <div>
                            <h3 className="text-light secondary-font fs-feedbak-s mt-4">Delivery Experience & Packaging</h3>
                            <span className="text-light secondary-font fs-feedbak-s">(Scale of 1-10)</span>
                            <FEEDBACKSCALE id="delivery" handleSelectedScale={setSelectedDeliveryScale} />
                        </div>
                        <div>
                            <h3 className="text-light secondary-font fs-feedbak-s mt-4">Value for Money</h3>
                            <span className="text-light secondary-font fs-feedbak-s">(Scale of 1-10)</span>
                            <FEEDBACKSCALE id="money" handleSelectedScale={setSelectedMoneyScale} />
                        </div>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col>
                        <h2 className="text-uppercase text-light primary-font fs-xs-feedbak-q">HOW LIKELY ARE YOU TO RECOMMEND SUSHIDO TO YOUR FRIENDS & FAMILY?</h2>
                        <span className="text-light secondary-font fs-feedbak-s">( 1 Being Least Likely to Recommend and 10 Being Definitely Recommend )</span>
                        <FEEDBACKSCALE id="recommend" handleSelectedScale={setSelectedRecommendScale} />
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col>
                        <h2 className="text-uppercase text-light primary-font fs-xs-feedbak-q">LET US KNOW IF ANY OTHER FEEDBACK!</h2>
                        <textarea className="form-control max-w-scale" rows={5} placeholder="Type here..." onChange={handleFeedback}></textarea>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col onClick={handleAddfeedback}>
                        <Button className="btn-submit">Submit</Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Feedback;