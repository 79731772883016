import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function Section3() {
    return (
        <Container className="mt-5">
            <h1 className="text-center text-uppercase text-light text-shadow secondry-font mb-5">SUSHI SQUAD SPECIALS</h1>
            <Row className="text-left mt-5">
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">TRUFFLE SALMON ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Luxury bites! Tempura shrimp, creamy
                        cheese, and avocado wrapped in salmon,
                        topped with cheddar snow, chili fire, and
                        truffle magic.🌟
                    </p>
                </Col>
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi1.png" fluid />
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi2.png" fluid />
                </Col>
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">SAN FRANCISCO ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Crispy, creamy, and spicy! Crab, avocado,
                        and cream cheese dance in tempura
                        perfection. Topped with guacamole,
                        spicy mayo, and sriracha heat!🔥
                    </p>
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">CREAMY LOBSTER ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Creamy sea dreams! Marinated lobster,
                        crispy tempura, cucumber, and cheese
                        topped with chives.
                    </p>
                </Col>
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi3.png" fluid />
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi4.png" fluid />
                </Col>
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">CRABBY CRUMBLE ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Crab delight! Tempura shrimp and crab
                        stick wrapped with a sprinkle of crunch,
                        black sesame, and spring onion magic.
                        Drizzled in our house special sauce. 🦀
                    </p>
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">PHILADELPHIA ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Elegantly delicious! Salmon, creamy
                        cheese, and avocado unite, topped with
                        love and vibrant orange tobiko.🧀
                    </p>
                </Col>
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi5.png" fluid />
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={4} md={6}>
                    <Image src="./assets/sushi6.png" fluid />
                </Col>
                <Col xs={8} md={6} className='my-auto'>
                    <h1 className="text-uppercase text-light primary-font fs-item-title">DRAGON ROLL</h1>
                    <p className="text-uppercase text-light secondary-font fs-item-description">
                        Exquisite balance! Crispy shrimp, creamy
                        avocado, and cheese wrapped in an
                        orange tobiko embrace. Topped with
                        chives, Japanese mayo, and creamy
                        sauce. 🐉
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Section3;