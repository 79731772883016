import './design'
import React, { useState, useEffect } from 'react'
/*React toastify library for notifications*/
import { ToastContainer, toast } from 'react-toastify'
import HEADER from './components/header'
import BODY from './components/body'
import FEEDBACK from './components/feedback'
import SQUAD from './components/squad'
import FOOTER from './components/footer'

function App() {
  const [page, setpage] = useState('home');
  const notify = (MSG) => toast(MSG); //Function to show system's notification
  const warning = (MSG) => toast.error(MSG); //Function to show system's error notification

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var pageurl = url.searchParams.get("page");
    setpage(pageurl);
  }, []);

  function openURL(url) {
    window.location = url
  }

  return (
    <div className="App">
      <HEADER openURL={openURL} />
      {
        (page === 'home' || page === null) ? (<BODY />) : (console.log("Missing param"))
      }
      {
        (page === 'feedback') ? (<FEEDBACK notify={notify} warning={warning} />) : (console.log("Missing param"))
      }
      {
        (page === 'squad') ? (<SQUAD notify={notify} warning={warning} />) : (console.log("Missing param"))
      }
      <FOOTER openURL={openURL} />
      {/*Notification box configuration*/}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
