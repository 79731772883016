import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

function Section1() {
    return (
        <Container>
            <Row className="my-5">
                <Col xs={12} md={6} className="fw-bold fs-sectio1 lh-1 secondary-font">ROLLIN'<br />WITH<br />FLAVOR </Col>
                <Col xs={12} md={6}>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                        <Image src="./assets/sushi1.png" fluid/>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="./assets/sushi2.png" fluid/>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="./assets/sushi3.png" fluid/>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="./assets/sushi4.png" fluid/>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="./assets/sushi5.png" fluid/>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="./assets/sushi6.png" fluid/>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
}

export default Section1;