import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Section2() {
    return (
        <Container className="my-5">
            <Row className="text-center mb-4">
                <Col>
                    <h1 className="text-uppercase text-light primary-font">FEELING HUNGRY?</h1>
                    <p className="text-uppercase text-light secondary-font fs-4 fw-bold">TO DIVE INTO OUR FUSION SUSHI! PEEP THE MENU</p>
                    <a href='https://linktr.ee/sushidome' className="text-uppercase text-link secondary-font fs-4 fw-bold">Click Here</a>
                </Col>
            </Row>
            <Row className="text-center mb-4">
                <Col>
                    <h1 className="text-uppercase text-light primary-font">CRUSHED THOSE ROLLS?</h1>
                    <p className="text-uppercase text-light secondary-font fs-4 fw-bold">TO SHARE THE VIBES AND LEAVE YOUR FEEDBACK</p>
                    <a href='/?page=feedback' className="text-uppercase text-link secondary-font fs-4 fw-bold">Click Here</a>
                </Col>
            </Row>
            <Row className="text-center mb-4">
                <Col>
                    <h1 className="text-uppercase text-light primary-font">SUSHIDO SQUAD, ASSEMBLE!</h1>
                    <p className="text-uppercase text-light secondary-font fs-4 fw-bold">TO SPILL THE SOY! LET'S CONNECT</p>
                    <a href='/?page=squad' className="text-uppercase text-link secondary-font fs-4 fw-bold">Click Here</a>
                </Col>
            </Row>
        </Container>
    );
}

export default Section2;