import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'

function Header({ openURL }) {

  return (
    <Navbar>
      <Container>
        <Navbar.Brand href="/">
          <Image src="./assets/logo.png" className='w-50' />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <ListGroup className="text-center" horizontal>
            <ListGroup.Item className="border-0 bg-transparent" onClick={() => openURL('https://www.tiktok.com/@sushido.dxb?_t=8hBdycTF4eg&_r=1')}><Image src="./assets/tiktok.png" className="social-icon" /></ListGroup.Item>
            <ListGroup.Item className="border-0 bg-transparent" onClick={() => openURL('https://www.facebook.com/sushido.me')}><Image src="./assets/facebook.png" className="social-icon" /></ListGroup.Item>
            <ListGroup.Item className="border-0 bg-transparent" onClick={() => openURL('https://www.instagram.com/sushido.me/')}><Image src="./assets/instagram.png" className="social-icon" /></ListGroup.Item>
          </ListGroup>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;