import SECTION1 from './section1'
import SECTION2 from './section2'
import SECTION3 from './section3'
import SECTION4 from './section4'
function Body() {
    return (
        <>
            <div className="bgc-body mb-5">
                <SECTION1 />
                <SECTION2 />
                <SECTION3 />
            </div>
            <SECTION4 />
        </>
    );
}

export default Body;